import React, { useEffect } from 'react';
import hive from "./index"
import { project } from "./project/project"
import { useState } from "@hookstate/core"

const lots: any = {
    "F03": "F-03",
    "F04": "F-04",
    "F05": "F-05",
    "C3.41": "C3-41",
    "C3.42": "C3-42",
    "F01": "F-01",
    "F02": "F-02",

    "C3.32": "C3-32",
    "C3.33": "C3-33",
    "C3.34": "C3-34",
    "C3.35": "C3-35",
    "C3.22": "C3-22",
    "C3.23": "C3-23",
    "C3.24": "C3-24",
    "C3.25": "C3-25",
    "C3.31": "C3-31",
    "C3.12": "C3-12",
    "C3.13": "C3-13",
    "C3.14": "C3-14",
    "C3.15": "C3-15",
    "C3.21": "C3-21",
    "C3.03": "C3-03",
    "C3.04": "C3-04",
    "C3.11": "C3-11",
    "C2.43": "C2-43",
    "C2.44": "C2-44",
    "C3.01": "C3-01",
    "C3.02": "C3-02",
    "C2.33": "C2-33",
    "C2.34": "C2-34",
    "C2.41": "C2-41",
    "C2.42": "C2-42",
    "C2.24": "C2-24",
    "C2.31": "C2-31",
    "C2.32": "C2-32",
    "C2.13": "C2-13",
    "C2.14": "C2-14",
    "C2.21": "C2-21",
    "C2.22": "C2-22",
    "C2.23": "C2-23",
    "C2.03": "C2-03",
    "C2.11": "C2-11",
    "C2.12": "C2-12",
    "C1.52": "C1-52",
    "C2.01": "C2-01",
    "C2.02": "C2-02",
    "C1.42": "C1-42",
    "C1.43": "C1-43",
    "C1.44": "C1-44",
    "C1.51": "C1-51",
    "C1.31": "C1-31",
    "C1.32": "C1-32",
    "C1.33": "C1-33",
    "C1.34": "C1-34",
    "C1.41": "C1-41",
    "C1.21": "C1-21",
    "C1.22": "C1-22",
    "C1.23": "C1-23",
    "C1.24": "C1-24",
    "C1.11": "C1-11",
    "C1.12": "C1-12",
    "C1.13": "C1-13",
    "C1.14": "C1-14",
    "C1.01": "C1-01",
    "C1.02": "C1-02",
    "C1.03": "C1-03",

    "B1.01": "B1-01",
    "B1.02": "B1-02",
    "B1.03": "B1-03",
    "B1.04": "B1-04",
    "B1.11": "B1-11",
    "B1.12": "B1-12",
    "B1.13": "B1-13",
    "B1.14": "B1-14",
    "B1.15": "B1-15",
    "B1.21": "B1-21",
    "B1.22": "B1-22",
    "B1.23": "B1-23",
    "B1.24": "B1-24",
    "B1.25": "B1-25",
    "B1.31": "B1-31",
    "B1.32": "B1-32",
    "B1.33": "B1-33",
    "B1.34": "B1-34",
    "B1.35": "B1-35",
    "B1.41": "B1-41",
    "B1.42": "B1-42",
    "B1.43": "B1-43",
    "B1.44": "B1-44",
    "B1.45": "B1-45",
    "B1.51": "B1-51",
    "B1.52": "B1-52",
    "B2.01": "B2-01",
    "B2.02": "B2-02",
    "B2.03": "B2-03",
    "B2.04": "B2-04",
    "B2.11": "B2-11",
    "B2.12": "B2-12",
    "B2.13": "B2-13",
    "B2.14": "B2-14",
    "B2.15": "B2-15",
    "B2.21": "B2-21",
    "B2.22": "B2-22",
    "B2.23": "B2-23",
    "B2.24": "B2-24",
    "B2.25": "B2-25",
    "B2.31": "B2-31",
    "B2.32": "B2-32",
    "B2.33": "B2-33",
    "B2.34": "B2-34",
    "B2.35": "B2-35",
    "B2.41": "B2-41",
    "B2.42": "B2-42",
    "B2.43": "B2-43",
    "B2.44": "B2-44",
    "B2.45": "B2-45",
    "B2.51": "B2-51",
    "B2.52": "B2-52",
    "B2.53": "B2-53",
}

export function TourEmbed(props: any) {
    return <div style={{
        width: "100%",
        height: "100%",
    }}>
        <div style={{
            width: "100%",
            height: "100%",
            overflow: "hidden"
        }}>
            {/* <iframe src={`https://www.theasys.io/viewer/${props.tourId}`} scrolling="no" allow="vr;gyroscope;accelerometer"
                style={{
                    width: '100%',
                    height: "100%",
                    border: "none"
                }}>
            </iframe> */}
            <iframe src={`https://www.realdesigner3d.fr/cloud/bouygues/visu/0004_${lots[props.tourId]}`}
                style={{
                    width: '100%',
                    height: "100%",
                    border: "none"
                }}>
            </iframe>
        </div>
    </div>
}

function Iframe() {
    const { width, height, mainColor, tour, activeScene } = useState(hive.state)

    return <div style={{
        background: "white",
        position: "absolute",
        height: height.get() + 'px',
        width: "100vw",
        zIndex: 2,
        display: "flex",
    }}>
        {project.tourDisclaimer && hive.getSceneById(activeScene.get()).type && <>
            <div style={{
                position: "absolute",
                left: width.get() * 0.2,
                top: height.get() * 0.020,
                color: `#${mainColor.get()}`,
                fontSize: height.get() * 0.023,
            }}>
                {activeScene.get() && <>
                    Visite virtuelle d'un logement type T{hive.getSceneById(activeScene.get()).type}
                </>}
            </div>
        </>}
        <TourEmbed tourId={activeScene.get()}></TourEmbed>
    </div>
}

export default Iframe;